// actionTypes/adCampaignActionTypes.js
export const FETCH_AD_CAMPAIGN_REQUEST = 'FETCH_AD_CAMPAIGN_REQUEST';
export const FETCH_AD_CAMPAIGN_SUCCESS = 'FETCH_AD_CAMPAIGN_SUCCESS';
export const FETCH_AD_CAMPAIGN_FAILURE = 'FETCH_AD_CAMPAIGN_FAILURE';

export const ADD_AD_CAMPAIGN_REQUEST = 'ADD_AD_CAMPAIGN_REQUEST';
export const ADD_AD_CAMPAIGN_SUCCESS = 'ADD_AD_CAMPAIGN_SUCCESS';
export const ADD_AD_CAMPAIGN_FAILURE = 'ADD_AD_CAMPAIGN_FAILURE';

export const UPDATE_AD_CAMPAIGN_REQUEST = 'UPDATE_AD_CAMPAIGN_REQUEST';
export const UPDATE_AD_CAMPAIGN_SUCCESS = 'UPDATE_AD_CAMPAIGN_SUCCESS';
export const UPDATE_AD_CAMPAIGN_FAILURE = 'UPDATE_AD_CAMPAIGN_FAILURE';

export const DELETE_AD_CAMPAIGN_REQUEST = 'DELETE_AD_CAMPAIGN_REQUEST';
export const DELETE_AD_CAMPAIGN_SUCCESS = 'DELETE_AD_CAMPAIGN_SUCCESS';
export const DELETE_AD_CAMPAIGN_FAILURE = 'DELETE_AD_CAMPAIGN_FAILURE';

// Fetch Ad Campaigns
export const fetchAdCampaignRequest = () => ({
    type: FETCH_AD_CAMPAIGN_REQUEST
});

export const fetchAdCampaignSuccess = (adCampaigns) => ({
    type: FETCH_AD_CAMPAIGN_SUCCESS,
    payload: adCampaigns
});

export const fetchAdCampaignFailure = (error) => ({
    type: FETCH_AD_CAMPAIGN_FAILURE,
    payload: error
});

// Add Ad Campaign
export const addAdCampaignRequest = (adCampaignData) => ({
    type: ADD_AD_CAMPAIGN_REQUEST,
    payload: adCampaignData
});

export const addAdCampaignSuccess = (campaign) => {
    if (!campaign || !campaign.id) {
        console.error("addAdCampaignSuccess: Invalid campaign data", campaign)
        return {
            type: ADD_AD_CAMPAIGN_FAILURE,
            payload: "Invalid campaign data received from server",
        }
    }
    return {
        type: ADD_AD_CAMPAIGN_SUCCESS,
        payload: campaign,
    }
}
export const addAdCampaignFailure = (error) => ({
    type: ADD_AD_CAMPAIGN_FAILURE,
    payload: error
});

// Update Ad Campaign
export const updateAdCampaignRequest = (adCampaignData) => ({
    type: UPDATE_AD_CAMPAIGN_REQUEST,
    payload: adCampaignData
});

export const updateAdCampaignSuccess = () => ({
    type: UPDATE_AD_CAMPAIGN_SUCCESS
});

export const updateAdCampaignFailure = (error) => ({
    type: UPDATE_AD_CAMPAIGN_FAILURE,
    payload: error
});

// Delete Ad Campaign
export const deleteAdCampaignRequest = (campaignId) => ({
    type: DELETE_AD_CAMPAIGN_REQUEST,
    payload: campaignId
});

export const deleteAdCampaignSuccess = () => ({
    type: DELETE_AD_CAMPAIGN_SUCCESS
});

export const deleteAdCampaignFailure = (error) => ({
    type: DELETE_AD_CAMPAIGN_FAILURE,
    payload: error
});