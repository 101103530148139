
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    LineChart,
    Line,
    ScatterChart,
    Scatter,
    AreaChart,
    Area,
} from "recharts"
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Grid,
    Box,
    Tabs,
    Tab,
    useTheme,
    CircularProgress,
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import axiosClient from "../../../axios-client"
import { fetchAdCampaignRequest, fetchAdCampaignSuccess, fetchAdCampaignFailure } from "../../store/actions/adActions"
import { format } from "date-fns"

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"]
const NoDataMessage = () => (
    <Box display="flex" justifyContent="center" alignItems="center" height={300}>
        <Typography variant="h6" color="textSecondary">
            No data found
        </Typography>
    </Box>
)
const ChartLoadingIndicator = () => (
    <Box display="flex" justifyContent="center" alignItems="center" height={300}>
        <CircularProgress />
    </Box>
)

const AdCampaignStats = () => {
    const [activeTab, setActiveTab] = useState(0)
    const [timeRange, setTimeRange] = useState("all")
    const [topPerformingMetric, setTopPerformingMetric] = useState("ctr")
    const [distributionMetric, setDistributionMetric] = useState("impressions")
    const theme = useTheme()
    const dispatch = useDispatch()
    const { loading, adCampaigns, error } = useSelector((state) => state.adCampaigns)

    useEffect(() => {
        const fetchAllCampaigns = async () => {
            dispatch(fetchAdCampaignRequest())
            try {
                let allCampaigns = []
                let currentPage = 1
                let hasNextPage = true

                while (hasNextPage) {
                    const response = await axiosClient.get(`AdCampaign/?page=${currentPage}`)
                    allCampaigns = [...allCampaigns, ...response.data.results]
                    hasNextPage = response.data.next !== null
                    currentPage++
                }

                dispatch(fetchAdCampaignSuccess({ results: allCampaigns, count: allCampaigns.length }))
            } catch (error) {
                dispatch(fetchAdCampaignFailure(error.message))
            }
        }

        fetchAllCampaigns()
    }, [dispatch])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
    }

    const calculateCTR = (impressions, clicks) => {
        return impressions > 0 ? (clicks / impressions) * 100 : 0
    }

    const normalizeData = (campaigns) => {
        const maxImpressions = Math.max(...campaigns.map((c) => c.impressions))
        const maxClicks = Math.max(...campaigns.map((c) => c.clicks))
        const normalizationFactor = Math.max(maxImpressions, maxClicks)

        return campaigns.map((campaign) => ({
            ...campaign,
            normalizedImpressions: (campaign.impressions / normalizationFactor) * 100,
            normalizedClicks: (campaign.clicks / normalizationFactor) * 100,
        }))
    }

    const campaignsWithCTR =
        adCampaigns && adCampaigns.results
            ? adCampaigns.results.map((campaign) => ({
                ...campaign,
                ctr: calculateCTR(campaign.impressions, campaign.clicks),
            }))
            : []

    const totalImpressions = campaignsWithCTR.reduce((sum, campaign) => sum + campaign.impressions, 0)
    const totalClicks = campaignsWithCTR.reduce((sum, campaign) => sum + campaign.clicks, 0)

    const columns = [
        { field: "name", headerName: "Campaign Name", flex: 1 },
        { field: "clientName", headerName: "Client", flex: 1 },
        { field: "impressions", headerName: "Impressions", type: "number", flex: 1 },
        { field: "clicks", headerName: "Clicks", type: "number", flex: 1 },
        {
            field: "ctr",
            headerName: "CTR",
            type: "number",
            flex: 1,
            valueFormatter: (params) => {
                if (params.value == null) {
                    return ""
                }
                return `${params.value.toFixed(2)}%`
            },
        },
        {
            field: "duration",
            headerName: "Duration (s)",
            type: "number",
            flex: 1,
        },
        {
            field: "is_active",
            headerName: "Status",
            type: "boolean",
            flex: 1,
            valueFormatter: (params) => {
                if (params.value == null) {
                    return ""
                }
                return params.value ? "Active" : "Inactive"
            },
        },
        { field: "start_date", headerName: "Start Date", flex: 1 },
        { field: "end_date", headerName: "End Date", flex: 1 },
    ]

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        )
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        )
    }

    const getTopPerformingCampaigns = (campaigns, metric, limit = 5) => {
        return [...campaigns].sort((a, b) => b[metric] - a[metric]).slice(0, limit)
    }

    const getCampaignPerformanceOverTime = (campaigns) => {
        const performanceData = campaigns.flatMap((campaign) => {
            const startDate = new Date(campaign.start_date)

            // Set endDate to the current date if it's in the future
            let endDate = new Date(campaign.end_date)
            const currentDate = new Date()
            if (endDate > currentDate) {
                endDate = currentDate
            }

            const days = (endDate - startDate) / (1000 * 3600 * 24) + 1

            return Array.from({ length: days }, (_, index) => {
                const date = new Date(startDate.getTime() + index * 24 * 60 * 60 * 1000)
                return {
                    date: format(date, "yyyy-MM-dd"),
                    impressions: campaign.impressions / days,
                    clicks: campaign.clicks / days,
                    ctr: calculateCTR(campaign.impressions / days, campaign.clicks / days),
                }
            })
        })

        return performanceData
            .reduce((acc, curr) => {
                const existingEntry = acc.find((entry) => entry.date === curr.date)
                if (existingEntry) {
                    existingEntry.impressions += curr.impressions
                    existingEntry.clicks += curr.clicks
                    existingEntry.ctr = calculateCTR(existingEntry.impressions, existingEntry.clicks)
                } else {
                    acc.push(curr)
                }
                return acc
            }, [])
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    }
    const getDurationVsImpressions = (campaigns) => {
        return campaigns
            .map((campaign) => ({
                name: campaign.name,
                duration: campaign.duration,
                impressions: campaign.impressions,
            }))
            .sort((a, b) => a.duration - b.duration)
    }

    const filteredCampaigns = campaignsWithCTR.filter((campaign) => {
        if (timeRange === "all") return true
        const endDate = new Date(campaign.end_date)
        const now = new Date()
        const diffTime = Math.abs(now.getTime() - endDate.getTime())
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays <= Number.parseInt(timeRange)
    })

    const normalizedCampaigns = normalizeData(filteredCampaigns)

    const topPerformingCampaigns = getTopPerformingCampaigns(filteredCampaigns, topPerformingMetric)
    const performanceOverTime = getCampaignPerformanceOverTime(filteredCampaigns)

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const campaign = payload[0].payload
            return (
                <div style={{ backgroundColor: "white", padding: "10px", border: "1px solid #ccc" }}>
                    <p>{`${label}`}</p>
                    <p>{`Impressions: ${campaign.impressions.toLocaleString()}`}</p>
                    <p>{`Clicks: ${campaign.clicks.toLocaleString()}`}</p>
                    <p>{`CTR: ${calculateCTR(campaign.impressions, campaign.clicks).toFixed(2)}%`}</p>
                    <p>{`Duration: ${campaign.duration} seconds`}</p>
                </div>
            )
        }
        return null
    }

    return (
        <Card sx={{ width: "100%", boxShadow: 3, bgcolor: theme.palette.background.paper }}>
            <CardHeader
                title={
                    <Typography variant="h5" color="primary">
                        Statistiques de la campagne publicitaire
                    </Typography>
                }
            />
            <CardContent>
                <Box sx={{ mb: 2 }}>
                    <ButtonGroup variant="outlined" aria-label="time range">
                        <Button onClick={() => setTimeRange("all")} variant={timeRange === "all" ? "contained" : "outlined"}>
                            All Time
                        </Button>
                        <Button onClick={() => setTimeRange("30")} variant={timeRange === "30" ? "contained" : "outlined"}>
                            Last 30 Days
                        </Button>
                        <Button onClick={() => setTimeRange("7")} variant={timeRange === "7" ? "contained" : "outlined"}>
                            Last 7 Days
                        </Button>
                    </ButtonGroup>
                </Box>
                <Tabs value={activeTab} onChange={handleTabChange} centered>
                    <Tab label="Overview" />
                    <Tab label="Performance" />
                    <Tab label="Top Campaigns" />
                    <Tab label="All Campaigns" />
                </Tabs>

                {activeTab === 0 && (
                    <Grid container spacing={3} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Impressions vs Clicks
                            </Typography>
                            {normalizedCampaigns.length === 0 ? (
                                <NoDataMessage />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart
                                        data={normalizedCampaigns}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend />
                                        <Bar dataKey="normalizedImpressions" fill="#8884d8" name="Impressions" />
                                        <Bar dataKey="normalizedClicks" fill="#82ca9d" name="Clicks" />
                                    </BarChart>
                                </ResponsiveContainer>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Campaign Distribution
                            </Typography>
                            {filteredCampaigns.length === 0 ? (
                                <NoDataMessage />
                            ) : (
                                <Box>
                                    <FormControl sx={{ mb: 2 }}>
                                        <InputLabel id="distribution-metric-label">Distribution Metric</InputLabel>
                                        <Select
                                            labelId="distribution-metric-label"
                                            value={distributionMetric}
                                            onChange={(e) => setDistributionMetric(e.target.value)}
                                        >
                                            <MenuItem value="impressions">Impressions</MenuItem>
                                            <MenuItem value="clicks">Clicks</MenuItem>
                                            <MenuItem value="ctr">CTR</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <PieChart>
                                            <Pie
                                                data={filteredCampaigns}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                dataKey={distributionMetric}
                                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                            >
                                                {filteredCampaigns.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip />
                                            <Legend />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Performance Over Time
                            </Typography>
                            {performanceOverTime.length === 0 ? (
                                <NoDataMessage />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart data={performanceOverTime}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Area type="monotone" dataKey="impressions" stroke="#8884d8" fill="#8884d8" name="Impressions" />
                                        <Area type="monotone" dataKey="clicks" stroke="#82ca9d" fill="#82ca9d" name="Clicks" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            )}
                        </Grid>
                    </Grid>
                )}

                {activeTab === 1 && (
                    <Grid container spacing={3} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Click-Through Rate (CTR) Comparison
                            </Typography>
                            {filteredCampaigns.length === 0 ? (
                                <NoDataMessage />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={filteredCampaigns}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="ctr" stroke="#8884d8" activeDot={{ r: 8 }} name="CTR (%)" />
                                    </LineChart>
                                </ResponsiveContainer>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Campaign Duration vs Performance
                            </Typography>
                            {filteredCampaigns.length === 0 ? (
                                <NoDataMessage />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <ScatterChart>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="duration" name="Duration (s)" type="number" domain={["dataMin", "dataMax"]} />
                                        <YAxis dataKey="ctr" name="CTR (%)" />
                                        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                                        <Legend />
                                        <Scatter
                                            name="Campaigns"
                                            data={filteredCampaigns.sort((a, b) => a.duration - b.duration)}
                                            fill="#8884d8"
                                        />
                                    </ScatterChart>
                                </ResponsiveContainer>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Campaign Duration vs Impressions
                            </Typography>
                            {filteredCampaigns.length === 0 ? (
                                <NoDataMessage />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <ScatterChart>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="duration" name="Duration (s)" type="number" domain={["dataMin", "dataMax"]} />
                                        <YAxis dataKey="impressions" name="Impressions" />
                                        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                                        <Legend />
                                        <Scatter name="Campaigns" data={getDurationVsImpressions(filteredCampaigns)} fill="#82ca9d" />
                                    </ScatterChart>
                                </ResponsiveContainer>
                            )}
                        </Grid>
                    </Grid>
                )}

                {activeTab === 2 && (
                    <Grid container spacing={3} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Box sx={{ mb: 2 }}>
                                <ButtonGroup variant="outlined" aria-label="top performing metric">
                                    <Button
                                        onClick={() => setTopPerformingMetric("ctr")}
                                        variant={topPerformingMetric === "ctr" ? "contained" : "outlined"}
                                    >
                                        CTR
                                    </Button>
                                    <Button
                                        onClick={() => setTopPerformingMetric("impressions")}
                                        variant={topPerformingMetric === "impressions" ? "contained" : "outlined"}
                                    >
                                        Impressions
                                    </Button>
                                    <Button
                                        onClick={() => setTopPerformingMetric("clicks")}
                                        variant={topPerformingMetric === "clicks" ? "contained" : "outlined"}
                                    >
                                        Clicks
                                    </Button>
                                </ButtonGroup>
                            </Box>
                            <Typography variant="h6" gutterBottom>
                                Top Performing Campaigns
                            </Typography>
                            {topPerformingCampaigns.length === 0 ? (
                                <NoDataMessage />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={topPerformingCampaigns}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey={topPerformingMetric} fill="#8884d8" name={topPerformingMetric.toUpperCase()} />
                                    </BarChart>
                                </ResponsiveContainer>
                            )}
                        </Grid>
                    </Grid>
                )}

                {activeTab === 3 && (
                    <Box sx={{ height: 400, width: "100%", mt: 2 }}>
                        <DataGrid
                            rows={filteredCampaigns}
                            columns={columns}
                            pageSize={10}
                            rowCount={filteredCampaigns.length}
                            paginationMode="client"
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                        />
                    </Box>
                )}

                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Overall Statistics
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Total Campaigns
                                    </Typography>
                                    <Typography variant="h4">{filteredCampaigns.length}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Total Impressions
                                    </Typography>
                                    <Typography variant="h4">{totalImpressions.toLocaleString()}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Total Clicks
                                    </Typography>
                                    <Typography variant="h4">{totalClicks.toLocaleString()}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Average CTR
                                    </Typography>
                                    <Typography variant="h4">{calculateCTR(totalImpressions, totalClicks).toFixed(2)}%</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    )
}

export default AdCampaignStats

