
import { useState, useEffect } from "react"
import { GoogleMap, Marker, InfoWindow, useLoadScript } from "@react-google-maps/api"
import { Box, TextField, Button, Typography, Paper, Grid, CircularProgress } from "@mui/material"
import { Search as SearchIcon, MyLocation as MyLocationIcon } from "@mui/icons-material"
import PlaceCard from "./place-card"
import axiosClient from "../../../axios-client"

const MapContainer = {
    height: "70vh",
    width: "100%",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
}

const SearchContainer = {
    padding: "16px",
    marginBottom: "16px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
}

const libraries = ["places"]

const NearestLocationsMap = () => {
    const [currentLocation, setCurrentLocation] = useState(null)
    const [places, setPlaces] = useState([])
    const [selectedPlace, setSelectedPlace] = useState(null)
    const [searchTerm, setSearchTerm] = useState("restaurants")
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [loading, setLoading] = useState(false)
    const [nearestPlace, setNearestPlace] = useState(null)

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyAqhnv0cxa3Ejgx-r9OIYLdcHxSy_Vfe28",
        libraries: libraries,
    })

    useEffect(() => {
        getCurrentLocation()
    }, [])

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords
                    setCurrentLocation({ lat: latitude, lng: longitude })
                    setLatitude(latitude.toString())
                    setLongitude(longitude.toString())
                    fetchNearestLocations(latitude, longitude, searchTerm)
                },
                (error) => {
                    console.error("Error getting location", error)
                },
            )
        }
    }
    const fetchNearestLocations = (latitude, longitude, searchTerm) => {
        setLoading(true);  // Start loading state

        axiosClient.get(
            `/nearest_zones/?latitude=${latitude}&longitude=${longitude}&search_term=${searchTerm}`
        )
            .then(response => {
                // Assuming axios already parses the response as JSON
                const data = response.data;
                setPlaces(data);

                if (data.length > 0) {
                    setNearestPlace(data[0]);
                }
            })
            .catch(error => {
                // Handle error
                console.error("Error fetching nearest locations:", error);
                // Optionally, update error state or show a message
                // setError("Failed to fetch nearest locations. Please try again later.");
            })
            .finally(() => {
                setLoading(false);  // Stop loading state after success or error
            });
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault()
        if (latitude && longitude) {
            fetchNearestLocations(Number.parseFloat(latitude), Number.parseFloat(longitude), searchTerm)
        }
    }

    const mapCenter = currentLocation || { lat: 30.403198, lng: -9.528803 }

    const renderMap = () => {
        return (
            <GoogleMap mapContainerStyle={MapContainer} center={mapCenter} zoom={14}>
                {currentLocation && (
                    <Marker
                        position={currentLocation}
                        icon={{
                            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                            scaledSize: new window.google.maps.Size(40, 40),
                        }}
                    />
                )}
                {Array.isArray(places) ? (
                    // Your rendering logic here
                    places.map((place, index) => (
                        <Marker
                            key={index}
                            position={{ lat: place.latitude, lng: place.longitude }}
                            icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                                scaledSize: new window.google.maps.Size(40, 40),
                            }}
                            onClick={() => setSelectedPlace(place)}
                        />
                    ))
                ) : (
                    <div>No places to display</div> // Optional: Message when no places available
                )}
                {selectedPlace && (
                    <InfoWindow
                        position={{ lat: selectedPlace.latitude, lng: selectedPlace.longitude }}
                        onCloseClick={() => setSelectedPlace(null)}
                    >
                        <PlaceCard place={selectedPlace} />
                    </InfoWindow>
                )}
            </GoogleMap>
        )
    }

    if (loadError) return <div>Error loading maps</div>
    if (!isLoaded) return <div>Loading maps</div>

    return (
        <Box sx={{ maxWidth: 1200, margin: "auto", padding: 2 }}>
            <Typography variant="h4" gutterBottom>
                Find Nearest Locations
            </Typography>
            <Paper style={SearchContainer}>
                <form onSubmit={handleSearchSubmit}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="Search Term"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                label="Latitude"
                                type="number"
                                value={latitude}
                                onChange={(e) => setLatitude(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                label="Longitude"
                                type="number"
                                value={longitude}
                                onChange={(e) => setLongitude(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Button fullWidth variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Grid>
                        {/* <Grid item xs={6} sm={1}>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                onClick={getCurrentLocation}
                                startIcon={<MyLocationIcon />}
                            >
                                Locate Me
                            </Button>
                        </Grid> */}
                    </Grid>
                </form>
                {nearestPlace && (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Nearest Place
                        </Typography>
                        <PlaceCard place={nearestPlace} />
                    </Box>
                )}
            </Paper>

            {renderMap()}

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    )
}

export default NearestLocationsMap

