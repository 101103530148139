import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import Breadcrumb from "./Breadcrumb/reducer";
import userReducer from "./reducers/userReducers";
import redioReducer from "./reducers/radioReducer";
import musiqueReducer from "./reducers/musiqueReducer";
import musicsHistoryReducer from "./reducers/musicsHReducer";

// Ad Campaigns Reducer
import adCampaignReducer from "./reducers/adReducer"; // Import the ad campaign reducer

const rootReducer = combineReducers({
  // public
  Layout,
  // Breadcrumb items
  Breadcrumb,

  // userReducer for users state
  users: userReducer,

  // Reducers for other modules
  radios: redioReducer,
  musiques: musiqueReducer,
  musicshistory: musicsHistoryReducer,

  // Adding the adCampaignReducer to the rootReducer
  adCampaigns: adCampaignReducer,  // This is the added reducer for Ad Campaigns
});

export default rootReducer;