import React from "react"
// import { Redirect } from "react-router-dom"



import Users from "../pages/Users/users"
import Radios from "../pages/Radios/radios"
import Credits from "../pages/Credits/credits"
import Visualisation from "../pages/Visualisation/visualisation"
import Habillage from "../pages/Habillage/habillage"
import Configuration from "../pages/Configuration/configuration"
import Programmes from "../pages/Programmes/programmes"
import AddImage from "../pages/AddImage/addImage"
import Pub from "../pages/Pub/pub"
import HomePage from "../../slidesShow/HomePage"
import UserProfile from "../pages/Users/user-profile"
import AddUser from "../pages/Users/AddUser"
import EditUser from "../pages/Users/EditUser"
import AccessRestricted from "../pages/acces-restricted/access-restricted"

import PreviewSlide from "../pages/Habillage/Aperçu/PreviewSlide"
import Dashboard from "../pages/dashboard/Dashboard"
import Musiques from "../pages/musique/musiques"
import musicsH from "../pages/musicshistory/musicsH"
import MusicsH from "../pages/musicshistory/musicsH"
import AdCompaign from "../pages/ads/AdCompaign"

const adminRoutes = [
  { path: "/users", component: Users },
  { path: "/dashboard", component: Dashboard },
  { path: "/radios", component: Radios },
  { path: "/credits", component: Credits },
  { path: "/visualisation", component: Visualisation },
  { path: "/habillage", component: Habillage },
  { path: "/configuration", component: Configuration },
  { path: "/programmes", component: Programmes },
  { path: "/pub", component: Pub },
  { path: "/addImage", component: AddImage },
  { path: "/addUser", component: AddUser },
  { path: "/editUser", component: EditUser },
  { path: "/Aperçu", component: PreviewSlide },
  { path: "/musicshistory", component: MusicsH },
  { path: "/profile", component: UserProfile },
  { path: "/adcompaign", component: AdCompaign },


  { path: "/unauthorized", component: AccessRestricted },


  { path: "/musiques", component: Musiques },





]



export { adminRoutes }