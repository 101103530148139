import React, { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { useStateContext } from "../../../context/ContextProvider"

const SidebarContent = props => {
  const ref = useRef()
  const location = useLocation()

  const { user, setToken, setUser, setRefresh, token } = useStateContext();


  // useEffect(() => {
  //   const pathName = location.pathname

  //   const initMenu = () => {
  //     new MetisMenu("#side-menu")
  //     let matchingMenuItem = null
  //     const ul = document.getElementById("side-menu")
  //     const items = ul.getElementsByTagName("a")
  //     for (let i = 0; i < items.length; ++i) {
  //       if (pathName === items[i].pathname) {
  //         matchingMenuItem = items[i]
  //         break
  //       }
  //     }
  //     if (matchingMenuItem) {
  //       activateParentDropdown(matchingMenuItem)
  //     }
  //   }
  //   initMenu()
  // }, [location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  console.log("location.pathname", location.pathname);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }


  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%", backgroundColor: '#232939' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">METROPOLYS </li>
            <li>
              <Link to="/dashboard" className=" waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            {user.role === "Manager" && <li>
              <Link to="/users" className="waves-effect">
                <i className="mdi mdi-account-multiple"></i>
                {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                <span>Utilisateurs</span>
              </Link>
            </li>}

            <li>
              <Link to="/radios" className=" waves-effect">
                <i className="mdi mdi-radio"></i>
                <span>Radios</span>
              </Link>
            </li>

            <li>
              <Link to="/musiques" className=" waves-effect">
                <i className="mdi mdi-music"></i>
                <span>Musiques</span>
              </Link>
            </li>
            <li>
              <Link to="/musicshistory" className=" waves-effect">
                <i className="mdi mdi-music"></i>
                <span>Historique des Musiques</span>
              </Link>
            </li>
            <li>
              <Link to="/adcompaign" className="waves-effect">
                <i className="mdi mdi-bullhorn"></i>
                <span>Ad Campaigns</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/credits" className=" waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>Crédits</span>
              </Link>
            </li>  */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-radio"></i>
                <span>Ma Radio</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {/* <li>
                  <Link to="/visualisation">Visualisation</Link>
                </li> */}
                <li>
                  <Link to="/habillage">Habillage </Link>
                </li>
                {/* <li>
                  <Link to="/configuration">Configuration </Link>
                </li> */}
              </ul>
            </li>
            {/* <li>
              <Link to="/programmes" className=" waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>Programmes</span>
              </Link>
            </li>

            <li>
              <Link to="/pub" className=" waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>Pub</span>
              </Link>
            </li>

            <li>
              <Link to="/addImage" className=" waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>Ajouter une image</span>
              </Link>
            </li> */}



          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default withTranslation()(SidebarContent)


















// import PropTypes from "prop-types"
// import React, { useEffect, useRef } from "react"

// // //Import Scrollbar
// import SimpleBar from "simplebar-react"

// // MetisMenu
// import MetisMenu from "metismenujs"
// // import { withRouter } from "react-router-dom"
// import { Link } from "react-router-dom"
// import { withRouter } from "../../hooks/withRouter"

// //i18n
// import { withTranslation } from "react-i18next"

// const SidebarContent = props => {
//   const ref = useRef()
//   // Use ComponentDidMount and ComponentDidUpdate method symultaniously
//   useEffect(() => {
//     const pathName = props.location.pathname

//     const initMenu = () => {
//       new MetisMenu("#side-menu")
//       let matchingMenuItem = null
//       const ul = document.getElementById("side-menu")
//       const items = ul.getElementsByTagName("a")
//       for (let i = 0; i < items.length; ++i) {
//         if (pathName === items[i].pathname) {
//           matchingMenuItem = items[i]
//           break
//         }
//       }
//       if (matchingMenuItem) {
//         activateParentDropdown(matchingMenuItem)
//       }
//     }
//     initMenu()
//   }, [props.location.pathname])

//   useEffect(() => {
//     ref.current.recalculate()
//   })

//   function scrollElement(item) {
//     if (item) {
//       const currentPosition = item.offsetTop
//       if (currentPosition > window.innerHeight) {
//         ref.current.getScrollElement().scrollTop = currentPosition - 300
//       }
//     }
//   }

//   function activateParentDropdown(item) {
//     item.classList.add("active")
//     const parent = item.parentElement
//     const parent2El = parent.childNodes[1]
//     if (parent2El && parent2El.id !== "side-menu") {
//       parent2El.classList.add("mm-show")
//     }

//     if (parent) {
//       parent.classList.add("mm-active")
//       const parent2 = parent.parentElement

//       if (parent2) {
//         parent2.classList.add("mm-show") // ul tag

//         const parent3 = parent2.parentElement // li tag

//         if (parent3) {
//           parent3.classList.add("mm-active") // li
//           parent3.childNodes[0].classList.add("mm-active") //a
//           const parent4 = parent3.parentElement // ul
//           if (parent4) {
//             parent4.classList.add("mm-show") // ul
//             const parent5 = parent4.parentElement
//             if (parent5) {
//               parent5.classList.add("mm-show") // li
//               parent5.childNodes[0].classList.add("mm-active") // a tag
//             }
//           }
//         }
//       }
//       scrollElement(item);
//       return false
//     }
//     scrollElement(item);
//     return false
//   }

//   return (
//     <React.Fragment>
//       <SimpleBar style={{ maxHeight: "100%", backgroundColor: '#232939' }} ref={ref}>
//         <div id="sidebar-menu">
//           <ul className="metismenu list-unstyled" id="side-menu">
//             <li className="menu-title">METROPOLYS </li>
//             <li>
//               <Link to="/users" className="waves-effect">
//                 <i className="mdi mdi-view-dashboard"></i>
//                 {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
//                 <span>Utilisateurs</span>
//               </Link>
//             </li>

//             <li>
//               <Link to="/radios" className=" waves-effect">
//                 <i className="mdi mdi-calendar-check"></i>
//                 <span>Radios</span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/credits" className=" waves-effect">
//                 <i className="mdi mdi-calendar-check"></i>
//                 <span>Crédits</span>
//               </Link>
//             </li>

//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="mdi mdi-calendar-check"></i>
//                 <span>Ma Radio</span>
//               </Link>
//               <ul className="sub-menu" aria-expanded="false">
//                 <li>
//                   <Link to="/visualisation">Visualisation</Link>
//                 </li>
//                 <li>
//                   <Link to="/habillage">Habillage </Link>
//                 </li>
//                 <li>
//                   <Link to="/configuration">Configuration </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/programmes" className=" waves-effect">
//                 <i className="mdi mdi-calendar-check"></i>
//                 <span>Programmes</span>
//               </Link>
//             </li>

//             <li>
//               <Link to="/pub" className=" waves-effect">
//                 <i className="mdi mdi-calendar-check"></i>
//                 <span>Pub</span>
//               </Link>
//             </li>

//             <li>
//               <Link to="/addImage" className=" waves-effect">
//                 <i className="mdi mdi-calendar-check"></i>
//                 <span>Ajouter une image</span>
//               </Link>
//             </li>



//           </ul>
//         </div>
//       </SimpleBar>
//     </React.Fragment>
//   )
// }

// SidebarContent.propTypes = {
//   location: PropTypes.object,
//   t: PropTypes.any,
// }

// export default withRouter(withTranslation()(SidebarContent))
