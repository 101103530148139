
import { useState, useEffect } from "react"
import { Card, CardBody, ModalBody, Col, Modal, ModalFooter, ModalHeader, Row, Table, Media } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
    addAdCampaignFailure,
    addAdCampaignRequest,
    addAdCampaignSuccess,
    fetchAdCampaignFailure,
    fetchAdCampaignRequest,
    fetchAdCampaignSuccess,
} from "../../store/actions/adActions"
import axiosClient from "../../../axios-client"
import { Avatar, Divider, Pagination, Stack, Switch } from "@mui/material"
import { Alert } from "react-bootstrap"
import { FaPencilAlt } from "react-icons/fa"
import { RiImageAddFill } from "react-icons/ri"
import { Button } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { IoPencil, IoTrash } from "react-icons/io5"

export default function AdCampaigns() {
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [modal_center, setmodal_center] = useState(false)
    const [changeModal, setChangeModal] = useState(true)

    const [name, setName] = useState("")
    const [clientName, setClientName] = useState("")
    const [description, setDescription] = useState("")
    const [campaignId, setCampaignId] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [duration, setDuration] = useState(0)
    const [selectedImage, setSelectedImage] = useState(null)
    const [clickUrl, setClickUrl] = useState("")
    const [isActive, setIsActive] = useState(true)

    const [message, setMessage] = useState("")
    const [nameMessageError, setNameMessageError] = useState(null)

    const [adCampaignData, setAdCampaignData] = useState([])
    const [adCampaignId, setAdCampaignId] = useState(null)

    const { loading, adCampaigns, error } = useSelector((state) => state.adCampaigns)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        dispatch(fetchAdCampaignRequest())
        axiosClient
            .get(`AdCampaign/?page=${page}`)
            .then((response) => {
                dispatch(fetchAdCampaignSuccess(response.data))
                setTotalPages(Math.ceil(response.data.count / 10))
            })
            .catch((error) => {
                dispatch(fetchAdCampaignFailure(error.message))
            })
    }, [dispatch, page])

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const toggle = () => setModal(!modal)

    function tog_center() {
        setmodal_center(!modal_center)
        if (!modal_center) {
            // Reset all form fields and adCampaignData when opening the modal
            setSelectedImage(null)
            setName("")
            setClientName("")
            setDescription("")
            setCampaignId("")
            setStartDate("")
            setEndDate("")
            setDuration(5)
            setClickUrl("")
            setIsActive(true)
            setMessage("")
            setAdCampaignData({
                id: null,
                name: "",
                clientName: "",
                description: "",
                campaignId: "",
                start_date: "",
                end_date: "",
                duration: 0,
                click_url: "",
                media_file: null,
                is_active: true,
            })
        }
    }
    // Method to fetch the updated list of campaigns
    const fetchAdCampaignList = async (page) => {
        try {
            const response = await axiosClient.get(`AdCampaign/?page=${page}`)
            dispatch(fetchAdCampaignSuccess(response.data))
            setTotalPages(Math.ceil(response.data.count / 10))
        } catch (error) {
            console.log("Error fetching ad campaigns:", error)
        }
    }

    // The updated handleSubmit function
    const handleSubmit = async () => {
        if (!name || !campaignId || !startDate || !endDate || !duration || !clickUrl) {
            setMessage("Veuillez remplir tous les champs obligatoires!")
            return
        }

        const formData = new FormData()
        formData.append("name", name)
        formData.append("clientName", clientName)
        formData.append("description", description)
        formData.append("campaignId", campaignId)
        formData.append("start_date", startDate)
        formData.append("end_date", endDate)
        formData.append("duration", duration)
        formData.append("click_url", clickUrl)
        formData.append("is_active", isActive.toString())
        if (selectedImage) {
            formData.append("media_file", selectedImage)
        }

        dispatch(addAdCampaignRequest(formData))

        try {
            const response = await axiosClient.post("AdCampaign/", formData)
            toast.success("La campagne a été ajoutée avec succès", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            })

            setmodal_center(false)
            dispatch(addAdCampaignSuccess(response.data))

            // Fetch the updated list of campaigns after adding the new one
            await fetchAdCampaignList(page)
        } catch (error) {
            console.error("Error adding ad campaign:", error)
            setMessage(error.response?.data?.error || "Error adding ad campaign")
            dispatch(addAdCampaignFailure(error.message))
        }
    }
    const [loadingE, setLoadingE] = useState(false)
    // Method to handle the GET request for fetching updated data
    const fetchAdCampaignData = async (page) => {
        try {
            const response = await axiosClient.get(`AdCampaign/?page=${page}`)
            // toast.success("La campagne a été modifiée avec succès", {
            //     position: "top-center",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: false,
            //     pauseOnHover: false,
            //     draggable: false,
            //     progress: undefined,
            //     theme: "light",
            // })
            dispatch(fetchAdCampaignSuccess(response.data))
            setTotalPages(Math.ceil(response.data.count / 10))
        } catch (error) {
            console.log(error)
        }
    }

    // The updated handleEditSubmit function
    const handleEditSubmit = async (id) => {
        setLoadingE(true) // Show loader

        const formDataEdit = new FormData()
        if (name) formDataEdit.append("name", name)
        if (clientName) formDataEdit.append("clientName", clientName)
        if (description) formDataEdit.append("description", description)
        if (campaignId) formDataEdit.append("campaignId", campaignId)
        if (startDate) formDataEdit.append("start_date", new Date(startDate).toISOString())
        if (endDate) formDataEdit.append("end_date", new Date(endDate).toISOString())
        if (duration) formDataEdit.append("duration", duration)
        if (clickUrl) formDataEdit.append("click_url", clickUrl)
        formDataEdit.append("is_active", isActive.toString())
        if (selectedImage) formDataEdit.append("media_file", selectedImage)

        try {
            await axiosClient.patch(`AdCampaign/${id}/`, formDataEdit)

            toast.success("La campagne a été modifiée avec succès", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            })
            setmodal_center(false)
            // After PATCH success, fetch the updated campaign data
            await fetchAdCampaignData(page)
        } catch (error) {
            console.error("Error editing ad campaign:", error)
        } finally {
            setLoadingE(false) // Hide loader after completion
        }
    }
    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };
    // The updated handleDelete function
    const handleDelete = (id) => {
        if (!id) return

        axiosClient
            .delete(`/AdCampaign/${id}/`)
            .then((response) => {
                // After DELETE success, fetch the updated campaign data
                fetchAdCampaignData(page)

                toast.success("La campagne a été supprimée avec succès", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                })

                toggle() // Close the modal or reset any UI state related to deleting
            })
            .catch((error) => {
                console.error("Error deleting ad campaign:", error)
            })
    }

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error}</div>
    }


    return (
        <div>
            <ToastContainer />
            <Row className="icons-demo-content">
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <h4 className="card-title" style={{ fontWeight: "bold" }}>
                                Gestion des Campagnes Publicitaires
                            </h4>
                            <Row className="mb-3">
                                <Col lg={12} className="d-flex justify-content-end">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            tog_center()
                                            setChangeModal(true)
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                    >
                                        <i style={{ fontSize: "17px", marginRight: "5px" }} className="mdi mdi-plus" />
                                        Ajouter une campagne
                                    </button>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div style={{ maxHeight: "450px", overflowY: "auto" }} className="table-responsive">
                                                <Table responsive className="table-primary table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Nom</th>
                                                            <th>Client</th>
                                                            <th>Url</th>
                                                            <th>Date de début</th>
                                                            <th>Date de fin</th>
                                                            <th>Statut</th>
                                                            {/* <th>Durée</th> */}
                                                            <th>Image</th>
                                                            <th>Options</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {adCampaigns && adCampaigns.results && adCampaigns.results.length > 0 ? (
                                                            adCampaigns.results.map((campaign) => (
                                                                <tr key={campaign.id}>
                                                                    <th scope="row">{campaign.campaignId}</th>
                                                                    <td
                                                                        title={campaign.name}
                                                                        style={{
                                                                            maxWidth: "100px",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                            whiteSpace: "nowrap",
                                                                        }}
                                                                    >
                                                                        {campaign.name.length > 10 ? `${campaign.name.slice(0, 10)}...` : campaign.name}
                                                                    </td>
                                                                    <td
                                                                        title={campaign.clientName}
                                                                        style={{
                                                                            maxWidth: "100px",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                            whiteSpace: "nowrap",
                                                                        }}
                                                                    >
                                                                        {campaign.clientName.length > 10
                                                                            ? `${campaign.clientName.slice(0, 10)}...`
                                                                            : campaign.clientName}
                                                                    </td>

                                                                    <td>
                                                                        <a
                                                                            href={campaign.click_url}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{
                                                                                display: "inline-block",
                                                                                maxWidth: "150px",
                                                                                textOverflow: "ellipsis",
                                                                                overflow: "hidden",
                                                                                whiteSpace: "nowrap",
                                                                            }}
                                                                        >
                                                                            {campaign.click_url.length > 10
                                                                                ? `${campaign.click_url.slice(0, 20)}...`
                                                                                : campaign.click_url}
                                                                        </a>
                                                                    </td>
                                                                    <td>{formatDate(campaign.start_date)}</td>
                                                                    <td>{formatDate(campaign.end_date)}</td>
                                                                    <td>{campaign.is_active ? "Active" : "Inactive"}</td>
                                                                    {/* <td>{campaign.duration} secondes</td> */}
                                                                    <td>
                                                                        <Avatar alt="Campaign media" src={campaign.media_file} />
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setChangeModal(false)
                                                                                tog_center()
                                                                                axiosClient
                                                                                    .get(`AdCampaign/${campaign.id}/`)
                                                                                    .then((response) => {
                                                                                        const campaignData = response.data
                                                                                        setAdCampaignData(campaignData)
                                                                                        setAdCampaignId(campaignData.id)
                                                                                        setName(campaignData.name)
                                                                                        setClientName(campaignData.clientName)
                                                                                        setDescription(campaignData.description)
                                                                                        setCampaignId(campaignData.campaignId)
                                                                                        setStartDate(campaignData.start_date.slice(0, 16)) // Format for datetime-local input
                                                                                        setEndDate(campaignData.end_date.slice(0, 16)) // Format for datetime-local input
                                                                                        setDuration(campaignData.duration)
                                                                                        setClickUrl(campaignData.click_url)
                                                                                        setIsActive(campaignData.is_active)
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        console.error("Error fetching campaign data:", error)
                                                                                        toast.error("Error fetching campaign data")
                                                                                    })
                                                                            }}
                                                                            variant="outline-primary"
                                                                        >
                                                                            <IoPencil fontSize={"18px"} />
                                                                        </Button>{" "}
                                                                        <Button
                                                                            onClick={() => {
                                                                                toggle()
                                                                                setAdCampaignId(campaign.id)
                                                                            }}
                                                                            variant="danger"
                                                                        >
                                                                            <IoTrash fontSize={"18px"} />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="9" className="text-center">
                                                                    No campaigns found
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="d-flex justify-content-end">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={Math.ceil((adCampaigns && adCampaigns.count ? adCampaigns.count : 0) / 10)}
                                            color="secondary"
                                            page={page}
                                            onChange={handlePageChange}
                                        />
                                    </Stack>
                                </Col>
                            </Row>
                        </CardBody>

                        <Modal
                            size="lg"
                            isOpen={modal_center}
                            toggle={() => {
                                tog_center()
                            }}
                            centered={true}
                        >
                            <ModalHeader
                                toggle={() => {
                                    tog_center()
                                }}
                            >
                                <h3>{changeModal ? "Ajouter une campagne" : "Modifier la campagne"}</h3>
                            </ModalHeader>
                            <ModalBody className="p-4 mx-2">
                                <form encType="multipart/form-data">
                                    <Row>
                                        <Col style={{ alignContent: "center" }} lg="3">
                                            <Media className="mb-3" style={{ justifyContent: "center", alignItems: "center" }}>
                                                <div className="position-relative" style={{ width: 150, height: 150 }}>
                                                    <Avatar
                                                        style={{ objectFit: "cover", borderRadius: "5%" }}
                                                        sx={{ width: 150, height: 150 }}
                                                        src={selectedImage ? URL.createObjectURL(selectedImage) : adCampaignData.media_file || ""}
                                                        variant="square"
                                                    />
                                                    <label
                                                        htmlFor="imageInput"
                                                        className="position-absolute"
                                                        style={{
                                                            cursor: "pointer",
                                                            bottom: -15,
                                                            right: -10,
                                                            border: "3px solid white",
                                                            borderRadius: "50%",
                                                        }}
                                                    >
                                                        <input
                                                            id="imageInput"
                                                            type="file"
                                                            name="media_file"
                                                            accept="image/*"
                                                            style={{ display: "none" }}
                                                            onChange={(e) => setSelectedImage(e.target.files[0])}
                                                        />
                                                        <div
                                                            className="d-flex justify-content-center align-items-center"
                                                            style={{ backgroundColor: "#232939", borderRadius: "50%", width: 30, height: 30 }}
                                                        >
                                                            {changeModal ? (
                                                                <RiImageAddFill size={15} color="#fff" />
                                                            ) : (
                                                                <FaPencilAlt size={15} color="#fff" />
                                                            )}
                                                        </div>
                                                    </label>
                                                </div>
                                            </Media>
                                        </Col>
                                        <Col style={{ display: "flex", justifyContent: "center" }} lg="1">
                                            <Divider style={{ borderColor: "gray" }} orientation="vertical" variant="middle" />
                                        </Col>
                                        <Col lg="8">
                                            {message && <Alert variant={"danger"}>{message}</Alert>}
                                            <Row className="mb-3">
                                                <label htmlFor="name" className="col-md-3 col-form-label">
                                                    Nom de la campagne
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        required
                                                        style={{ border: nameMessageError ? "2px solid red" : "" }}
                                                        className="form-control"
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                    {nameMessageError && <p style={{ color: "red" }}> {nameMessageError}</p>}
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label htmlFor="clientName" className="col-md-3 col-form-label">
                                                    Nom du client
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="clientName"
                                                        name="clientName"
                                                        value={clientName}
                                                        onChange={(e) => setClientName(e.target.value)}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label htmlFor="description" className="col-md-3 col-form-label">
                                                    Description
                                                </label>
                                                <div className="col-md-9">
                                                    <textarea
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label htmlFor="campaignId" className="col-md-3 col-form-label">
                                                    ID de la campagne
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="campaignId"
                                                        name="campaignId"
                                                        value={campaignId}
                                                        onChange={(e) => setCampaignId(e.target.value)}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label htmlFor="startDate" className="col-md-3 col-form-label">
                                                    Date de début
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="datetime-local"
                                                        id="startDate"
                                                        name="start_date"
                                                        value={startDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label htmlFor="endDate" className="col-md-3 col-form-label">
                                                    Date de fin
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="datetime-local"
                                                        id="endDate"
                                                        name="end_date"
                                                        value={endDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label htmlFor="duration" className="col-md-3 col-form-label">
                                                    Durée (secondes)
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="number"
                                                        id="duration"
                                                        name="duration"
                                                        value={duration}
                                                        onChange={(e) => setDuration(e.target.value)}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label htmlFor="clickUrl" className="col-md-3 col-form-label">
                                                    URL de redirection
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="url"
                                                        id="clickUrl"
                                                        name="click_url"
                                                        value={clickUrl}
                                                        onChange={(e) => setClickUrl(e.target.value)}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label htmlFor="isActive" className="col-md-3 col-form-label">
                                                    Campagne active
                                                </label>
                                                <div className="col-md-9">
                                                    <Switch
                                                        checked={isActive}
                                                        onChange={(e) => setIsActive(e.target.checked)}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                    />
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <button
                                    type="button"
                                    onClick={() => {
                                        changeModal ? handleSubmit() : handleEditSubmit(adCampaignId)
                                    }}
                                    className="btn btn-primary waves-effect waves-light"
                                    disabled={loadingE} // Disable button while loading
                                >
                                    {loadingE ? (
                                        <>
                                            <i className="mdi mdi-loading mdi-spin" style={{ fontSize: "17px", marginRight: "5px" }} />
                                            Modification en cours...
                                        </>
                                    ) : (
                                        <>
                                            <i style={{ fontSize: "17px", marginRight: "5px" }} className="mdi mdi-content-save" />
                                            {changeModal ? "Ajouter la campagne" : "Modifier la campagne"}
                                        </>
                                    )}
                                </button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle}>Supprimer</ModalHeader>
                            <ModalBody>Êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.</ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggle} variant="secondary">
                                    Annuler
                                </Button>{" "}
                                <Button onClick={() => handleDelete(adCampaignId)} variant="danger">
                                    Supprimer
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

