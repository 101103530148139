import {
    FETCH_AD_CAMPAIGN_REQUEST,
    FETCH_AD_CAMPAIGN_SUCCESS,
    FETCH_AD_CAMPAIGN_FAILURE,
    ADD_AD_CAMPAIGN_REQUEST,
    ADD_AD_CAMPAIGN_SUCCESS,
    ADD_AD_CAMPAIGN_FAILURE,
    UPDATE_AD_CAMPAIGN_REQUEST,
    UPDATE_AD_CAMPAIGN_SUCCESS,
    UPDATE_AD_CAMPAIGN_FAILURE,
    DELETE_AD_CAMPAIGN_REQUEST,
    DELETE_AD_CAMPAIGN_SUCCESS,
    DELETE_AD_CAMPAIGN_FAILURE,
} from "../actions/adActions"

const initialState = {
    loading: false,
    adCampaigns: {
        results: [],
        count: 0,
    },
    adCampaign: null,
    error: "",
}

const adCampaignReducer = (state = initialState, action) => {
    switch (action.type) {
        // Fetch Ad Campaigns
        case FETCH_AD_CAMPAIGN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_AD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                adCampaigns: {
                    results: Array.isArray(action.payload.results) ? action.payload.results : [],
                    count: action.payload.count || 0,
                },
                error: "",
            }
        case FETCH_AD_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                adCampaigns: {
                    results: [],
                    count: 0,
                },
                error: action.payload,
            }

        // Add a New Ad Campaign
        case ADD_AD_CAMPAIGN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADD_AD_CAMPAIGN_SUCCESS:
            // Check if payload exists and has an id
            if (action.payload && action.payload.id) {
                return {
                    ...state,
                    loading: false,
                    adCampaigns: {
                        results: [...state.adCampaigns.results, action.payload],
                        count: state.adCampaigns.count + 1,
                    },
                    error: "",
                }
            } else {
                // If payload or id is missing, just return the current state
                console.error("ADD_AD_CAMPAIGN_SUCCESS: Payload or id is missing", action.payload)
                return {
                    ...state,
                    loading: false,
                    error: "Error adding campaign: Invalid response from server",
                }
            }
        case ADD_AD_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        // Update an Existing Ad Campaign
        case UPDATE_AD_CAMPAIGN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_AD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                adCampaigns: {
                    ...state.adCampaigns,
                    results: state.adCampaigns.results.map((campaign) =>
                        campaign.id === action.payload.id ? action.payload : campaign,
                    ),
                },
                error: "",
            }
        case UPDATE_AD_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        // Delete an Ad Campaign
        case DELETE_AD_CAMPAIGN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DELETE_AD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                adCampaigns: {
                    results: state.adCampaigns.results.filter((campaign) => campaign.id !== action.payload.id),
                    count: state.adCampaigns.count - 1,
                },
                error: "",
            }
        case DELETE_AD_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        default:
            return state
    }
}

export default adCampaignReducer

