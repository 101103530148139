const IframeTester = () => {
    return (
        <div style={{
            width: "100%",
            height: "100%"
        }}>
            <iframe src="https://livestream.digi-screen.com/player/x9bq8x6/x14aak"

                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    transition: 'opacity 0.3s ease-in-out'
                }}
            />


            {/* <iframe src="https://livestream.digi-screen.com/"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    transition: 'opacity 0.3s ease-in-out'
                }}
            /> */}

        </div>
    )
}

export default IframeTester