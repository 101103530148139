import { Card, CardContent, CardActions, Typography, Button, Rating, Box } from "@mui/material"
import { Place as PlaceIcon, Directions as DirectionsIcon } from "@mui/icons-material"

const PlaceCard = ({ place }) => {
    return (
        <Card elevation={3}>
            <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                    {place.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    <PlaceIcon fontSize="small" sx={{ verticalAlign: "middle", mr: 0.5 }} />
                    {place.address}
                </Typography>
                {/* <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Rating value={place.rating} readOnly precision={0.1} size="small" />
                    <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                        ({place.rating})
                    </Typography>
                </Box> */}
                <Typography variant="body2" color="text.primary">
                    Distance: {place.distance}
                </Typography>
                <Typography variant="body2" color="text.primary">
                    Duration: {place.duration}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="primary"
                    startIcon={<DirectionsIcon />}
                    href={place.google_maps_link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Directions
                </Button>
            </CardActions>
        </Card>
    )
}

export default PlaceCard

