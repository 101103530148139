import { useEffect, useState } from "react"
import { Row, Col, Card, Table } from "react-bootstrap"
import "./custom.css"
import { Avatar, AvatarGroup, Pagination, Stack } from "@mui/material"
import { MdOutlineRadio, MdPadding } from "react-icons/md"
import Slider from "react-infinite-logo-slider"
import axiosClient, { link } from "../../../axios-client"
import { FaUserTie } from "react-icons/fa6"
import { GiMusicalNotes } from "react-icons/gi"

import CryptoJS from "crypto-js"
import AdCampaignStats from "../ads/AdCompaignStats"
import NearestZonesMap from "../ads/MapT"

const CardComponent = ({ bgGradientClass, amount, description, svgPath }) => (
    <Col xs={12} sm={6} md={6} lg={4} xl={3}>
        <div
            className={`relative p-4 rounded-xl overflow-hidden ${bgGradientClass}`}
            style={{ maxHeight: "120px", borderRadius: "10px" }}
        >
            <div className="relative z-10 mb-3 text-white " style={{ fontWeight: "bold", fontSize: 25 }}>
                {amount}
            </div>
            <div className="relative z-10 text-white" style={{ fontWeight: "semi-bold" }}>
                {description}
            </div>
            <svg
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    height: 140,
                    width: 140,
                    marginRight: -30,
                    marginBottom: -30,
                    opacity: 25,
                }}
                className="-mr-8 -mb-8 text-white opacity-25 z-0"
            >
                {svgPath}
            </svg>
        </div>
    </Col>
)

function Dashboard() {
    const [count, setCount] = useState()
    const [countUsers, setCountUsers] = useState()
    const [countRadioOwners, setCountRadioOwners] = useState()
    const [radios, setRadios] = useState()
    const [radioUser, setRadioUser] = useState()
    const paginationSize = 3
    const [page, setPage] = useState(1)
    const [page2, setPage2] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalPages2, setTotalPages2] = useState(1)

    const [countMusicWithLyrics, setCountMusicWithLyrics] = useState()
    const [countMusicWithoutLyrics, setCountMusicWithoutLyrics] = useState()
    const [countMusiques, setCountMusiques] = useState()

    useEffect(() => {
        axiosClient
            .get("/radios/getRadioCount/")
            .then((res) => {
                setCount(res.data)
            })
            .catch((error) => {
                console.error(error)
            })

        axiosClient
            .get("/users/getRadioOwnersCount/")
            .then((res) => {
                setCountRadioOwners(res.data)
            })
            .catch((error) => {
                console.error(error)
            })

        axiosClient
            .get("/users/getUsersCount/")
            .then((res) => {
                setCountUsers(res.data)
            })
            .catch((error) => {
                console.error(error)
            })

        axiosClient
            .get(`/radios/?page=${page}`)
            .then((res) => {
                setRadios(res.data.results)
                setTotalPages(Math.ceil(res.data.count / paginationSize))
            })
            .catch((error) => {
                console.error(error)
            })

        axiosClient
            .get("/users/get_user_radio_simple_data/")
            .then((res) => {
                setRadioUser(res.data.results)
                setTotalPages2(Math.ceil(res.data.count / paginationSize))
            })
            .catch((error) => {
                console.error(error)
            })

        axiosClient
            .get("/musiques/getTotalMusic/")
            .then((res) => {
                setCountMusiques(res.data)
            })
            .catch((error) => {
                console.error(error)
            })

        axiosClient
            .get("/musiques/getMusicWithLyrics/")
            .then((res) => {
                setCountMusicWithLyrics(res.data)
            })
            .catch((error) => {
                console.error(error)
            })

        axiosClient
            .get("/musiques/getMusicWithoutLyrics/")
            .then((res) => {
                setCountMusicWithoutLyrics(res.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [count, countUsers, countRadioOwners, countMusicWithLyrics, countMusicWithoutLyrics, countMusiques])

    const handlePageChange = (event, value) => {
        setPage(value)
    }
    const handlePageChange2 = (event, value) => {
        setPage2(value)
    }

    const startIndex = (page - 1) * paginationSize
    const endIndex = startIndex + paginationSize

    const startIndex2 = (page2 - 1) * paginationSize
    const endIndex2 = startIndex2 + paginationSize

    return (
        <div>
            <div className="py-3 d-flex align-items-center justify-content-center">
                <div className="px-3" style={{ width: "100%" }}>
                    <Row className="g-3">
                        <CardComponent
                            bgGradientClass="bg-gradient-teal-green"
                            amount={count ? count : 0}
                            description="Radios"
                            svgPath={<MdOutlineRadio size={22} />}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-blue"
                            amount={countRadioOwners ? countRadioOwners : 0}
                            description="Radio owners"
                            svgPath={<FaUserTie size={22} />}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-red"
                            amount={countUsers ? countUsers : 0}
                            description="Utilisateurs"
                            svgPath={
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                                />
                            }
                        />

                        {/* <CardComponent
                            bgGradientClass="bg-gradient-yellow"
                            // amount={ (countMusicWithLyrics && countMusicWithoutLyrics) ?`${countMusicWithLyrics}% | ${countMusicWithoutLyrics}%` : '-'}
                            amount="500 musiques"
                            description={ (countMusicWithLyrics && countMusicWithoutLyrics) ?` ${countMusicWithLyrics}% | ${countMusicWithoutLyrics}%` : '-'}
                            svgPath={
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            }
                        /> */}
                        <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                            <div
                                className={`relative p-4 rounded-xl overflow-hidden bg-gradient-yellow`}
                                style={{ maxHeight: "120px", borderRadius: "10px" }}
                            >
                                <div className="relative z-10 mb-3 text-white " style={{ fontWeight: "bold", fontSize: 20 }}>
                                    {countMusiques ? countMusiques : 0} musiques
                                </div>
                                <div className="relative z-10 text-white" style={{ fontWeight: "semi-bold", fontSize: 15 }}>
                                    <span style={{ fontWeight: "bold", fontSize: 18 }}>{countMusicWithLyrics}% </span>avec -{" "}
                                    <span style={{ fontWeight: "bold", fontSize: 18 }}>{countMusicWithoutLyrics}% </span>sans paroles
                                </div>
                                <svg
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    style={{
                                        position: "absolute",
                                        right: 0,
                                        bottom: 0,
                                        height: 140,
                                        width: 140,
                                        marginRight: -30,
                                        marginBottom: -30,
                                        opacity: 25,
                                    }}
                                    className="-mr-8 -mb-8 text-white opacity-25 z-0"
                                >
                                    <GiMusicalNotes size={22} />
                                </svg>
                            </div>
                        </Col>
                        {/* <Col xs={12} sm={6} lg={3}>
                            <div className={`relative p-4 rounded-xl overflow-hidden bg-gradient-yellow`} style={{ maxHeight: '120px', borderRadius: "10px" }}>
                            <div className="relative z-10 text-white" style={{ fontWeight: "bold", fontSize: 15 }}>
                            <span> 500 musiques</span>
                                </div>

                            

                                <div className="relative z-10 mb-2 text-white" style={{ fontWeight: "bold", fontSize: 20 }}>
                                    {(countMusicWithLyrics && countMusicWithoutLyrics) ? (
                                        <> 
                                            <span style={{ color: '#3572a9' }}>{countMusicWithLyrics}%</span> | <span style={{ color: '#336f52' }}>{countMusicWithoutLyrics}%</span>
                                        </>
                                    ) : '-'}
                                </div>
                                <div className="relative z-10 text-white" style={{ fontWeight: "semi-bold", display: "flex", alignItems: "center", gap: 3 }}>Musiques <span style={{ color: '#3572a9', fontWeight: 'bold' }}>avec</span>  | <span style={{ color: '#336f52', fontWeight: 'bold' }}>sans</span> paroles</div>
                                <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ position: "absolute", right: 0, bottom: 0, height: 140, width: 140, marginRight: -30, marginBottom: -30, opacity: 25 }} className="-mr-8 -mb-8 text-white opacity-25 z-0">
                                    {<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                    }
                                </svg>
                            </div>

                        </Col> */}
                    </Row>
                </div>
            </div>

            <div className="px-3 ">
                <div className="logos">
                    <Slider width="250px" duration={15} pauseOnHover={true} blurBorders={false}>
                        {radios?.map((row) => (
                            <Slider.Slide>
                                <img
                                    style={{ width: 100, height: 100, borderRadius: 50 }}
                                    src={row.logo || "/placeholder.svg"}
                                    alt="any"
                                />
                            </Slider.Slide>
                        ))}
                        {/* <Slider.Slide>
                            <img style={{ width: 100, height: 100 }} src="https://clipartcraft.com/images/transparent-background-logo-8.png" alt="any" />
                        </Slider.Slide>
                        <Slider.Slide>
                            <img style={{ width: 100, height: 100 }} src="https://i.imgur.com/OH7Yxoy.png" alt="any2" />
                        </Slider.Slide>
                        <Slider.Slide>
                            <img style={{ width: 100, height: 100 }} src="https://clipground.com/images/logos-png-8.png" alt="any2" />
                        </Slider.Slide>
                        <Slider.Slide>
                            <img style={{ width: 100, height: 100 }} src="https://netuseracc.com/wp-content/uploads/2020/06/SITE-STOON-12.png" alt="any2" />
                        </Slider.Slide> */}
                    </Slider>
                </div>
            </div>
            <Row style={{ padding: 0, margin: 0 }}>
                <div className="px-3 pt-4 col-xl-8 col-12 ">
                    <Card className="p-0" style={{ background: "transparent", borderRadius: "10px", height: "100%" }}>
                        <Card.Body>
                            <Card.Title>Radio owners</Card.Title>

                            <Card.Body className="p-2">
                                <Table size="sm">
                                    <thead>
                                        <tr style={{ borderColor: "lightgray" }}>
                                            <th>Utilisateur</th>
                                            <th>email</th>
                                            <th>Radios</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {radioUser?.slice(startIndex2, endIndex2).map((row) => (
                                            <tr style={{ borderColor: "lightgray" }}>
                                                <td>
                                                    <div className="d-flex p-2" style={{ alignItems: "center", gap: 10 }}>
                                                        <Avatar src={row.image} />
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <p className="p-0 m-0">{row.first_name}</p>
                                                            <p className="p-0 m-0">{row.last_name}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex p-2">{row.email}</div>
                                                </td>
                                                <td>
                                                    <div className="d-flex p-2 ">
                                                        <AvatarGroup max={3}>
                                                            {row.radios.map((radio) => (
                                                                <Avatar alt={radio.name} src={link + radio.logo} />
                                                            ))}
                                                        </AvatarGroup>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Row>
                                    <Col lg={12} className="d-flex justify-content-center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                size="small"
                                                count={totalPages2}
                                                color="secondary"
                                                page={page2}
                                                siblingCount={0}
                                                onChange={handlePageChange2}
                                            />
                                        </Stack>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card.Body>
                    </Card>
                </div>
                <div className=" px-3 pt-4 col-xl-4 col-12">
                    <Card className="p-0" style={{ background: "transparent", borderRadius: "10px", height: "100%" }}>
                        <Card.Body>
                            <Card.Title>Radios</Card.Title>

                            <Card.Body className="p-2">
                                <Table size="sm">
                                    <tbody>
                                        {radios?.slice(startIndex, endIndex).map((row) => (
                                            <tr>
                                                <td>
                                                    <div className="d-flex p-2" style={{ alignItems: "center", gap: 10 }}>
                                                        <Avatar src={row.logo} />
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <p className="p-0 m-0">{row.name}</p>
                                                            <p className="p-0 m-0">{row.tagRadio}</p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td style={{ alignItems: "center", justifyContent: "center", display: "flex", height: "100%" }}>
                                                    <div className="d-flex p-2 " style={{ justifyContent: "center", alignItems: "center" }}>
                                                        <MdOutlineRadio
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() =>
                                                                window.open(
                                                                    `/home/?r=${CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(CryptoJS.AES.encrypt(row.id.toString(), "secret-key").toString()))}`,
                                                                    "_blank",
                                                                )
                                                            }
                                                            color="#3c5d7f"
                                                            size={"25px"}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Row>
                                    <Col lg={12} className="d-flex justify-content-center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                size="small"
                                                count={totalPages}
                                                color="secondary"
                                                page={page}
                                                siblingCount={0}
                                                onChange={handlePageChange}
                                            />
                                        </Stack>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
            <Row className="mt-4">
                <Col xs={12}>
                    <AdCampaignStats />
                    <NearestZonesMap />
                </Col>
            </Row>
        </div>
    )
}

export default Dashboard

